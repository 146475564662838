<template>
  <div id='app' @click='bodyCloseMenus'>
    <router-view/>
  </div>
</template>
<script>
import {getUserInfor} from "@/API/user"
import w_button from "./components/button/w_button"
import {changeDateTimeType} from "@/API/changePrice";

export default {
  components: {},
  data() {
    return {}
  },
  created() {
    if (localStorage.getItem("access_token")) {
      this.getUserInformation()
    }
    const _isMobile = () => {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    }
    console.log('是不是手机', _isMobile() != null)
    // 判断是手机端还是H5
    if (_isMobile() != null) {
      window.location.href = "https://h5.paxtrade.pro"
    }
    this.getChangePrice()
  },
  mounted() {
  },

  methods: {
    bodyCloseMenus(e) {
      console.log("点击全屏", e)
      if (this.$store.state.optionShow == true) {
        this.$store.commit("changeOptionShow")
      }
    }
    ,
    // 获取用户信息
    getUserInformation() {
      getUserInfor().then(res => {
        console.log("获取用户信息", res)
        if (res.data.code == 200) {
          this.$store.commit("changeBalance", res.data.data.total_assets)
          this.$store.commit("changeOptions_balance", res.data.data.options_balance)
          this.$store.commit("changeContract_balance", res.data.data.contract_balance)
        }
      })
    },
    getChangePrice() {
      changeDateTimeType().then(res => {
        console.log('获取所有需要改动价格的数据', res)
        if(res.data.code==200){
          this.$store.dispatch("getChangeKlinePrice",res.data.data)
        }
      })
    }
  },


}
</script>

<style lang='scss'>
#app {
  width: 100%;
}

body {
  padding: 0;
  margin: 0;
  //overflow-x: hidden;
}

#app {

}
</style>
