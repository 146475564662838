export default {
    message: {
        index: {
            heardTab: {
                "buyCoins": "貨幣を買う",
                "transaction": "取り引き",
                "download": "ダウンロード",
                "personalCenter": "ユーザー",
                "options": "オプション",
                "contract": "先物",
                "optionDetail": "デジタル通貨の簡単な取引",
                "contractDetail": "トランザクションデジタル通貨契約",
                "signIn": "ログイン＃",
                "register": "登録",
                "userDetail": "ユーザーの詳細",
                "outLogin": "サインアウト",

            },
            bottomTab: {
                "clause": "条件とポリシー",
                "rate": "レート基準",
                "risk": "リスク提示",
                "moneyLaundering": "マネーロンダリング",
                "server": "サービス条件",
                "Privacy": "プライバシーに関する声明",
                "support": "マス通貨のサポート",
            },
            middleContent: {
                "line1": "paxfulproでの大量の暗号化通貨の購入、取引、保有",
                "line2": "簡単にいくつかのステップを実行して、最初の取引を開始します。",
                "line3": "通过我们的App，随时了解最新资讯。",
                "line4": "特定ビットコイン（BTC）現物取引を選択し、取引手数料ゼロを享受する",
                "line5": "グローバル1000000+ユーザー",
                "line6": "最近の市況",
                "line7": "市場と契約投資を選択できます」",
                "line8": "合理的な投資",
                "line9": "売り手と買い手の行動の完全な統計データを取得し、合理的な決定を支援します",
                "registerLearn": "登録について",
                "line11": "詳細なリアルタイム統計",
                "line12": "すべての関連情報をリアルタイムでどこでも表示し、運用方法と投資方法を決定できます。",
                "line13": "市場の最新相場を知り、すぐに収益を上げる",
                "join": "今すぐ参加",
            },
        },
        signIn: {
            "signIn": "ログイン＃",
            "userName": "ユーザー名",
            "password": "パスワード",
            "forget": "パスワードを忘れる?",
            "register": "登録",
        },
        forget: {
            "forget": "パスワードを忘れる",
            "email": "メールボックス",
            "code": "認証コード",
            "inputPassword": "新しいパスワードを入力",
            "confirmPassword": "新しいパスワードの確認",
            "confirm": "確認",
            "send": "送信",
            "warning1": "メールアドレスを入力してください",
            "warning2": "認証コードを入力してください",
            "warning3": "パスワードを入力してください",
            "warning4": "確認パスワードを入力してください",
            "warning5": "2回のパスワード入力が一致しないので、再入力してください。",
        },
        register: {
            "title": "登録",
            "username": "ユーザー名",
            "VerificationCode": "認証コード",
            "password": "パスワード",
            "InvitationCode": "招待コード",

        },
        download: {
            "line1": "高速トランザクション、セキュリティ・ストレージ、次世代暗号化通貨トランザクション製品",
            "line2": "いつでもどこでも取引を始めることができます",
            "line3": "安全で効率的な通貨取引アプリケーションのダウンロード",
        },
        contract: {
            "orderList": "オーダー表",
            "minute": "分",
            "hour": "時",
            "Time-sharing": "分時",
            "contract": "契約取引",
            "all": "全倉",
            "open": "倉庫をあける",
            "close": "平倉",
            "limit_order": "価格制限依頼",
            "market_order": "市価委託",
            "price": "価格",
            "inputPrice": "価格を入力してください",
            "amount": "数量",
            "inputAmount": "数量を入力してください",
            "money": "金額",
            "inputMoney": "金額を入力してください",
            "available": "使用可能",
            "avail": "可平",
            "buy_up": "購入する",
            "sell_out": "売り出す",
            "cost": "デポジット",
            "max_short": "オープン可能",
            "max_long": "可開多",
            "open_orders": "現在の委任",
            "positions": "現在の保管場所",
            "history": "履歴の委任",
            "symbol": "通貨＃ツウカ＃",
            "lever": "レバー",
            "direction": "方向",
            "hold_shares_volume": "持倉量",
            "open_average_price": "オープン平均価格",
            "operation": "操作",
            "noData": "データが一時的にありません",
            "margin_ratio": "デポジット率",
            "market_price": "マーク価格",
            "estliqprice": "強平を見積もる",
            "openPrice": "オープン平均価格",
            "closePrice": "平倉平均価格",
            "profit": "に利益を与える",
            "Yield": "収益率",
            "fee": "手数料",
            "total_amount": "委任合計",
            "time": "時間",
            "status": "ステータス",
            "close_fast": "フルフラット",
            "close_limit": "LT",
            "close_market": "MK",
            "availAmount": "へいかつりょう",
            "warning1": "まず価格を入力してください",
            "cancel": "キャンセル",
            "stop": "Stop",
            "startPrice": "Tトリガ価格",
            "takeProfit": "利益を取る",
            "stopLoss": "ストップロス",
            "message1": "テイクプロフィットまたはストップロスを選択してください",
            "message2": "ストップロストリガー価格を選択してください",
            "message3": "数量を選択してください",
            "message4": "テイクプロフィット価格は始値よりも低くしてはなりません",
            "message5": "ストップロス価格は始値よりも高くすることはできません",
        },
        options: {
            "current_trading": "現在の在庫",
            "order_history": "履歴",
            "open_time": "開倉時間",
            "close_time": "平倉時間",
            "count_down": "カウントダウン",
            "Buy_up": "買い増し",
            "Buy_down": "買いが落ちる",
            "options": "二元取引",
            "estimatedProfit": "利益の見積もり",
            "history": "最近の下落",
            "rise": "かさ上げ",
            "fall": "つまずく",
            "upAndDown": "の価値判断を",
            "dan": "シングル",
            "warning1": "残高が不足しています。取引数量を再入力してください",
            "warning2": "取引数量を正しく入力してください",
        },
        translateNav: {
            "line1": "通貨の検索",
            "line2": "すべて",
        },
        userInfo: {
            "title": "個人の概要",
            "nickname": "ニックネーム",
            "assets": "資産分布",
            "allAssets": "総資産",
            "Todayearnings": "今日の収益",
            "account": "アカウント",
            "CapitalAccount": "資金口座",
            "optionsAccount": "オプションアカウント",
            "contractAccount": "契約アカウント",
            "AssetEstimation": "資産推定",
            "Recharge": "チャージ",
            "CashWithdrawal": "現金化",
            "CapitalFlow": "資金フロー",
            "date": "日付",
            "quantity": "数量",
            "operation": "操作",
            "Transfer": "の方向をそらす",

        },
        Recharge: {
            "buy": "購入する",
            "WalletAddress": "ウォレットアドレス",
            "tip": "チャージタイプ",

        },
        withdrawal: {
            "withdrawal": "現金化",
            "WalletAddress": "ウォレットアドレス",
            "inputWalletAddress": "ウォレットアドレスを入力してください",
            "AvailableBalance": "使用可能残高",
            "all": "すべて引き出し",
            "wantWithdrawal": "私は現金を引き出したい",
            "selectNetWork": "ネットワークの選択",
            "withdrawalUSDT": "現金化USDT",
        },
        Transfer: {
            "title": "資金振替",
            "from": "開始",
            "to": "まで",
            "capitalAccount": "資金口座",
            "optionsAccount": "オプションアカウント",
            "contractAccount": "契約アカウント",
            "amount": "数量",
            "all": "すべてスクロール",
            "cancel": "キャンセル",
            "confirm": "確認",
        },
        assets: {
            "title": "資産分布",
            "allAssets": "総資産",
            "account": "アカウント",
            "Recharge": "Recharge",
            "withdrawal": "withdrawal",
            "Transfer": "Transfer",
        },
        assetEstimation: {
            "title": "資産推定",
        },
        capitalFlow: {
            "title": "資金フロー",
            "date": "日付",
            "amount": "数量",
            "operation": "操作",
            "Withdrawing": "現金化中",
        },
    },
}
