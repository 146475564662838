import store from "@/store"

export function openWebsocket() {
    let url = process.env.VUE_APP_SOCKET_API
    const websocketDom = new WebSocket(url)

// 打开状态
    websocketDom.onopen = () => {
        // 发送数据
        // let data1="{\"op\": \"subscribe\",\"args\":[{\"channel\":\"cup-tickers-3s\",\"ccy\":\"USDT\"}]}"
        // let data2="{\"op\": \"subscribe\",\"args\":[{\"channel\":\"notable-market-changes\"}]}"
        // let data3 = "{\"op\": \"subscribe\",\"args\":[{\"channel\":\"cup-tickers-3s\",\"ccy\":\"BTC\"}]}" //订阅订单表
        // let data4 = "{\"op\": \"subscribe\",\"args\":[{\"channel\":\"trades\",\"instId\":\"BTC-USDT\"}]}" //订阅最新成交表
        // let data5 = "{\"op\": \"subscribe\",\"args\":[{\"channel\":\"optimized-books\",\"instId\":\"BTC-USDT\"}]}" //订阅最新成交表

        // websocketDom.send(JSON.stringify(candle1DData))
        const candel1Ddata = candle1D()
        websocketDom.send(candel1Ddata)//订阅所有币种的1D

        const candel1mdata = candle1m()
        websocketDom.send(candel1mdata)//订阅所有币种的1m

        const candel15mdata = candle15m()
        websocketDom.send(candel15mdata)//订阅所有币种的15m

        const candel1Hmdata = candle1H()
        websocketDom.send(candel1Hmdata)//订阅所有币种的15m

        const candel4Hmdata = candle4H()
        websocketDom.send(candel4Hmdata)//订阅所有币种的4H

        const optimized_books_data = subscribe_optimized_books() //订阅所有币种的订单数据
        websocketDom.send(optimized_books_data)
        // websocketDom.send(data4)
        // websocketDom.send(data5)

        console.log("打开状态中")
    }
// 客户端接收服务端返回的数据
    websocketDom.onmessage = evt => {
        // console.log("websocket返回的数据：", evt.data)
        store.commit("change_socket_data", evt.data)
        let date = JSON.parse(evt.data)
        // console.log("解析后的数据", date)
        // if (date.arg.channel == "trades") {
        //   store.commit("change_trades_websocket_List", date.data[0])
        // }
        // if (date.arg.channel == "cup-tickers-3s") {
        //   store.commit("change_order_websocket_List", date)
        // }
        if (date.arg.channel == "optimized-books") {
            store.commit("change_optimized_books_websocket_List", date)
        }
        if (date.arg.channel == "index-tickers") {
            store.commit("change_realPrice_data", date)
        }
        // switch (date.type) {
        //   case "init":
        //     console.log(date)
        //     break
        //   case "ping":
        //     websocketDom.send("pong")
        //     break
        // }
    }

// 发生错误时
    websocketDom.onerror = evt => {
        console.log("websocket错误：", evt)
        // alert(12121)
    }

// websocketDom.close(),//关闭websocket
}

//订阅所有币种的1D数据
const candle1D = () => {
    let candleList1D = []
    let marketLists = store.state.marketList
    for (let i = 0; i < marketLists.length; i++) {
        let list = {channel: "candle1D", instId: marketLists[i].instId}
        candleList1D.push(list)
    }
    // 组合手法
    let candle1DData =
        {
            op: "subscribe",
            args: candleList1D,
        }

    // console.log("组合完之后的数据", JSON.stringify(candle1DData))
    return JSON.stringify(candle1DData)
}

//订阅所有币种的1m数据
const candle1m = () => {
    let candleList1m = []
    let marketLists = store.state.marketList
    for (let i = 0; i < marketLists.length; i++) {
        let list = {channel: "candle1m", instId: marketLists[i].instId}
        candleList1m.push(list)
    }
    // 组合手法
    let candle1mData =
        {
            op: "subscribe",
            args: candleList1m,
        }

    // console.log("组合完之后的数据", JSON.stringify(candle1mData))
    return JSON.stringify(candle1mData)
}

//订阅所有币种的15m数据
const candle15m = () => {
    let candleList15m = []
    let marketLists = store.state.marketList
    for (let i = 0; i < marketLists.length; i++) {
        let list = {channel: "candle15m", instId: marketLists[i].instId}
        candleList15m.push(list)
    }
    // 组合手法
    let candle15mData =
        {
            op: "subscribe",
            args: candleList15m,
        }

    // console.log("组合完之后的数据", JSON.stringify(candle15mData))
    return JSON.stringify(candle15mData)
}

//订阅所有币种的1H数据
const candle1H = () => {
    let candleList1H = []
    let marketLists = store.state.marketList
    for (let i = 0; i < marketLists.length; i++) {
        let list = {channel: "candle1H", instId: marketLists[i].instId}
        candleList1H.push(list)
    }
    // 组合手法
    let candle1HData =
        {
            op: "subscribe",
            args: candleList1H,
        }

    // console.log("组合完之后的数据", JSON.stringify(candle1HData))
    return JSON.stringify(candle1HData)
}

//订阅所有币种的4H数据
const candle4H = () => {
    let candleList4H = []
    let marketLists = store.state.marketList
    for (let i = 0; i < marketLists.length; i++) {
        let list = {channel: "candle4H", instId: marketLists[i].instId}
        candleList4H.push(list)
    }
    // 组合手法
    let candle4HData =
        {
            op: "subscribe",
            args: candleList4H,
        }

    // console.log("组合完之后的数据", JSON.stringify(candle4HData))
    return JSON.stringify(candle4HData)
}
//订阅订单表数据
const subscribe_optimized_books = () => {
    let optimized_books_list = []
    let marketLists = store.state.marketList
    for (let i = 0; i < marketLists.length; i++) {
        let list = {
            channel: "optimized-books",
            instId: marketLists[i].instId,
        }
        optimized_books_list.push(list)
    }
    let optimized_books_Data = {

        op: "subscribe",
        args: optimized_books_list,
    }

    // console.log("组合完之后的数据", JSON.stringify(optimized_books_Data))
    return JSON.stringify(optimized_books_Data)
}
