import Vue from "vue"
import Vuex from "vuex"

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        isAuth: localStorage.getItem("access_token"),//这个用来确认用户有没有登录
        marketList: [],//所有的市场币种
        optionShow: false,//交易页面打开切换弹窗的东西
        balance: 0,//用户的余额
        binarySymbol: "",//用户在二元交易时选择的symbol
        contractSymbol: "",//用户在期权symbol
        balance_available: "",//资金余额
        options_balance: "",//期权余额
        contract_balance: "",//合约余额
        trades_websocket_List: {},//socket最新成交表 trades
        order_websocket_list: {},//socket最新订单表 up-tickers-3s
        optimized_books_websocket_list: {},//socket最新订单表
        socket_data: "",//最新的所有的socket数据
        realPrice: {},//实时价格
        multiple: "",//如果合约里面的当前持仓有倍数，合约交易里面的倍数就不能调整
        binaryKlinePrice: "",//用户在期权页面下单后在表中显示一个\
        changeKlinePrice: [],//修改用户价格
        routerName: "",//用来确定各个页面的路径名称
    },
    mutations: {
        changeRouterName(state, val) {
            state.routerName = val
        },
        setChangeKlinePrice(state, val) {
            state.changeKlinePrice = val
        },
        //用来切换用户的登录状态
        changeIsAuth(state, val) {
            state.isAuth = val
        },
        changeMarketList(state, val) {
            state.marketList = val
        },
        changeOptionShow(state) {
            if (state.optionShow == true) {
                state.optionShow = false
            } else {
                state.optionShow = true
            }
        },
        changeBalance(state, val) {
            state.balance = val
        },
        changeBalance_available(state, val) {
            state.balance_available = val
        },
        // 修改binary的symbol
        changeBinarySymbol(state, val) {
            state.binarySymbol = val
        },
        // 修改contract的symbol
        changeContractSymbol(state, val) {
            state.contractSymbol = val
        },
        //修改期权账户
        changeOptions_balance(state, val) {
            state.options_balance = val
        },
        //修改合约余额
        changeContract_balance(state, val) {
            state.contract_balance = val
        },
        //修改最新成交表
        change_trades_websocket_List(state, val) {
            state.trades_websocket_List = val
        },
        //修改订单表
        change_order_websocket_List(state, val) {
            state.order_websocket_list = val
        },
        //修改订单表
        change_optimized_books_websocket_List(state, val) {
            state.optimized_books_websocket_list = val
        },
        change_socket_data(state, val) {
            state.socket_data = val
        },
        change_realPrice_data(state, val) {
            state.realPrice = val
        },
        change_multiple(state, val) {
            state.multiple = val
        },
        change_binaryKlinePrice(state, val) {
            state.binaryKlinePrice = val
        },
    },
    actions: {
        getChangeKlinePrice(context, val) {
            context.commit("setChangeKlinePrice", val)
        }
    },
    modules: {},
    getters: {
        marketLists(state) {
            return state.marketList
        },
        realPrices(state) {
            return state.realPrice
        },
    },
})
