import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import i18n from "./i18n/index"
import VueImg from "v-img-viewer"
import ElementUI from "element-ui"
import 'lib-flexible/flexible.js'
import locale from "element-ui/lib/locale/lang/en"
import "element-ui/lib/theme-chalk/index.css"
import "../src/assets/iconfont/iconfont.css"
import "../src/assets/styles/font_family.css"

Vue.use(VueImg, ElementUI)
Vue.use(ElementUI, { locale })
Vue.config.productionTip = false

if (process.env.NODE_ENV === "development") {
  console.log("开发环境");
} else {
  // console.log("生产环境");
  console.log = () => {};
}

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount("#app")
