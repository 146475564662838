import Vue from "vue"
import VueI18n from "vue-i18n"
import enLocale from "./lang/language-EN"
import cnLocale from "./lang/language-CN"
import jpLocale from "./lang/language-JP"
import tkyLocale from "./lang/language-TKY"
import krLocale from "./lang/language-KR"
import spinLocale from "./lang/language-SPIN"
import geLocale from "./lang/language-GE"
import FCLocale from "./lang/language-FC"

Vue.use(VueI18n)
const messages = {
  en: {
    ...enLocale,
  },
  cn: {
    ...cnLocale,
  },
  jp: {
    ...jpLocale,
  },
  tky: {
    ...tkyLocale,
  },
  kr: {
    ...krLocale,
  },
  fc: {
    ...FCLocale,
  },
  ge: {
    ...geLocale,
  },
  spin: {
    ...spinLocale,
  },
}
const i18n = new VueI18n({
  locale: localStorage.getItem("ucoinexLang") || "en",
  messages,
})

export default i18n
