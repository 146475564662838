export default {
  message: {
    index: {
      heardTab: {
        "buyCoins": "화폐를 사다",
        "transaction": "거래",
        "download": "다운로드",
        "personalCenter": "개인 센터",
        "options": "옵션",
        "contract": "계약",
        "optionDetail": "간편한 디지털 화폐 거래",
        "contractDetail": "거래 디지털 화폐 계약",
        "signIn": "로그인",
        "register": "등록",
        "userDetail": "사용자 세부 정보",
        "outLogin": "로그아웃",

      },
      bottomTab: {
        "clause": "약관 및 정책",
        "rate": "요율 기준",
        "risk": "위험 힌트",
        "moneyLaundering": "돈세탁 방지",
        "server": "서비스 약관",
        "Privacy": "개인 정보 보호 정책",
        "support": "대용량 통화 지원",
      },
      middleContent: {
        "line1": "paxfulpro에서 대용량 암호화폐 구매, 거래 및 보유",
        "line2": "간편한 첫 거래 시작",
        "line3": "App을 통해 최신 정보를 언제든지 확인할 수 있습니다.",
        "line4": "특정 비트코인(BTC) 현물 거래를 선택하면 제로 거래 수수료가 제공됩니다.",
        "line5": "전 세계 1000000+ 사용자",
        "line6": "최근 시장 시세",
        "line7": "시장 및 계약 투자를 선택할 수 있습니다.",
        "line8": "합리적인 투자",
        "line9": "판매자와 구매자의 행동에 대한 전체 통계를 수집하여 적절한 결정을 내릴 수 있습니다.",
        "registerLearn": "등록 이해",
        "line11": "상세한 실시간 통계",
        "line12": "어디에서나 실시간으로 모든 관련 정보를 확인하고 운영 및 투자 방법을 결정할 수 있습니다.",
        "line13": "시장의 최신 시세를 파악하여 즉시 수익을 얻다.",
        "join": "지금 가입",
      },
    },
    signIn: {
      "signIn": "로그인",
      "userName": "사용자 이름",
      "password": "암호",
      "forget": "암호를 잊었습니다.?",
      "register": "등록",
    },
    forget: {
      "forget": "암호를 잊었습니다.",
      "email": "메일박스",
      "code": "인증 코드",
      "inputPassword": "새 암호 입력",
      "confirmPassword": "새 암호 확인",
      "confirm": "확인",
      "send": "발송",
      "warning1": "이메일 계정을 입력하세요",
      "warning2": "인증 코드를 입력하십시오.",
      "warning3": "암호를 입력하십시오.",
      "warning4": "확인 암호를 입력하십시오.",
      "warning5": "암호 입력이 두 번 일치하지 않습니다. 다시 입력하십시오.",
    },
    register: {
      "title": "등록",
      "username": "사용자 이름",
      "VerificationCode": "인증 코드",
      "password": "암호",
      "InvitationCode": "초대 코드",

    },
    download: {
      "line1": "신속한 거래, 보안 스토리지, 차세대 암호화폐 거래 제품",
      "line2": "언제 어디서나 간편하게 거래 가능",
      "line3": "안전하고 효율적인 통화 거래 어플리케이션 다운로드",
    },
    contract: {
      "orderList": "주문 양식",
      "minute": "분할",
      "hour": "시",
      "Time-sharing": "시분할",
      "contract": "계약 거래",
      "all": "전창",
      "open": "전창",
      "close": "창고 정리",
      "limit_order": "가격 제한 위탁",
      "market_order": "시가 위탁",
      "price": "가격",
      "inputPrice": "가격을 입력하십시오.",
      "amount": "수량",
      "inputAmount": "수량을 입력하십시오.",
      "money": "금액",
      "inputMoney": "금액을 입력하세요",
      "available": "사용 가능",
      "avail": "가평",
      "buy_up": "매입",
      "sell_out": "매도",
      "cost": "보증금",
      "max_short": "비우기 가능",
      "max_long": "운전 가능 횟수",
      "open_orders": "현재 위임",
      "positions": "현재 창고 위치",
      "history": "역사적 의뢰",
      "symbol": "화폐 종류",
      "lever": "레버",
      "direction": "방향",
      "hold_shares_volume": "보유량",
      "open_average_price": "창고 개설 평균 가격",
      "operation": "작업",
      "noData": "데이터 없음",
      "margin_ratio": "보증금율",
      "market_price": "표시 가격",
      "estliqprice": "예상 강평",
      "openPrice": "창고 개설 평균 가격",
      "closePrice": "창고 정리 평균 가격",
      "profit": "이익",
      "Yield": "수익률",
      "fee": "수수료",
      "total_amount": "총 위탁량",
      "time": "시간",
      "status": "상태",
      "close_fast": "시가 전평",
      "close_limit": "제한 가격",
      "close_market": "시가",
      "availAmount": "가평량",
      "warning1": "먼저 가격을 입력하십시오.",
      "cancel": "취소",
      "stop": "Stop",
      "startPrice": "트리거 가격",
      "takeProfit": "이익을 얻다",
      "stopLoss": "손절매",
      "message1": "이익 실현 또는 손절매를 선택하십시오",
      "message2": "손절 발동 가격을 선택하십시오",
      "message3": "수량을 선택해주세요",
      "message4": "이익 실현 가격은 시작 가격보다 낮아서는 안 됩니다.",
      "message5": "손절매 가격은 시가보다 높을 수 없습니다.",
    },
    options: {
      "current_trading": "현재 보유",
      "order_history": "기록",
      "open_time": "창고 개설 시간",
      "close_time": "창고 정리 시간",
      "count_down": "카운트다운",
      "Buy_up": "매입",
      "Buy_down": "매수하다",
      "options": "이원거래",
      "estimatedProfit": "이윤을 예측하다.",
      "history": "최근 등락",
      "rise": "상승",
      "fall": "하락",
      "upAndDown": "등락",
      "dan": "단일",
      "warning1": "잔고가 부족합니다. 거래 수량을 다시 입력하십시오.",
      "warning2": "거래 수량을 정확하게 입력하십시오.",
    },
    translateNav: {
      "line1": "통화 검색",
      "line2": "모두",
    },
    userInfo: {
      "title": "개인 총람",
      "nickname": "닉네임",
      "assets": "자산 분포",
      "allAssets": "총자산",
      "Todayearnings": "오늘의 이익",
      "account": "계정",
      "CapitalAccount": "자금 계좌",
      "optionsAccount": "옵션 계좌",
      "contractAccount": "계약 계정",
      "AssetEstimation": "자산 예측",
      "Recharge": "충전",
      "CashWithdrawal": "현금 인출",
      "CapitalFlow": "자금 유통",
      "date": "날짜",
      "quantity": "수량",
      "operation": "작업",
      "Transfer": "회전",

    },
    Recharge: {
      "buy": "구매",
      "WalletAddress": "지갑 주소",
      "tip": "충전 유형",

    },
    withdrawal: {
      "withdrawal": "현금 인출",
      "WalletAddress": "지갑 주소",
      "inputWalletAddress": "지갑 주소를 입력하십시오.",
      "AvailableBalance": "사용 가능한 잔액",
      "all": "모두 현금 인출",
      "wantWithdrawal": "현금을 인출하겠습니다.",
      "selectNetWork": "选择네트워크 선택网络",
      "withdrawalUSDT": "USDT 현금 인출",
    },
    Transfer: {
      "title": "자금 이체",
      "from": "부터",
      "to": "도착",
      "capitalAccount": "자금 계좌",
      "optionsAccount": "옵션 계정",
      "contractAccount": "계약 계정",
      "amount": "수량",
      "all": "모두 회전",
      "cancel": "취소",
      "confirm": "확인",
    },
    assets: {
      "title": "자산 분포",
      "allAssets": "총자산",
      "account": "계정",
      "Recharge": "충전",
      "withdrawal": "현금 인출",
      "Transfer": "회전",
    },
    assetEstimation: {
      "title": "자산 예측",
    },
    capitalFlow: {
      "title": "자금 유통",
      "date": "날짜",
      "amount": "수량",
      "operation": "작업",
      "Withdrawing": "현금 인출 중",
    },
  },
}
