import request from '@/utils/request'


// 所有市场行情，就是总共有多少个币
export function allMarket(params) {
  return request({
    url: '/market/list?type=1&version=1.1.1',
    method: 'get',
    params
  });
}
