export default {
  message: {
    index: {
      heardTab: {
        "buyCoins": "buyCoins",
        "transaction": "transaction",
        "download": "download",
        "personalCenter": "userCenter",
        "options": "options",
        "contract": "contract",
        "optionDetail": "Easy trading",
        "contractDetail": "Contract trading",
        "signIn": "Sign In",
        "register": "register",
        "userDetail": "user details",
        "outLogin": "sign out",
      },
      bottomTab: {
        "clause": "Terms and policies",
        "rate": "Rate standard",
        "risk": "Risk statement",
        "moneyLaundering": "Anti-money laundering",
        "server": "Terms of service",
        "Privacy": "Privacy Statement",
        "support": "Support massive currency",
      },
      middleContent: {
        "line1": "Purchase, trade and hold massive cryptocurrency on paxfulpro",
        "line2": "Take a few easy steps to open the first transaction。",
        "line3": "Stay up to date with our App。",
        "line4": "Select a specific Bitcoin (BTC) spot transaction and enjoy zero transaction fee",
        "line5": "1000000+users worldwide",
        "line6": "Recent market conditions",
        "line7": "You can choose market and contract investment",
        "line8": "Reasonable investment",
        "line9": "Get complete statistical data on the behavior of sellers and buyers to help you make reasonable decisions",
        "registerLearn": "Register to learn",
        "line11": "Detailed real-time statistics",
        "line12": "You can view all relevant information in real time anywhere and decide how to operate and invest",
        "line13": "Get to know the latest market situation and earn profits immediately",
        "join": "Join now",
      },

    },
    signIn: {
      "signIn": "Sign in",
      "userName": "user name",
      "password": "password",
      "forget": "Forgot password?",
      "register": "register",
    },
    forget: {
      "forget": "Forgot password",
      "email": "mailbox",
      "code": "Verification Code",
      "inputPassword": "Enter a new password",
      "confirmPassword": "Confirm new password",
      "confirm": "confirm",
      "send": "send",
      "warning1": "Please enter your email account",
      "warning2": "Please enter the verification code",
      "warning3": "Please enter password",
      "warning4": "Please enter the confirmation password",
      "warning5": "The two passwords are inconsistent. Please re-enter them.",
    },
    register:{
      "title":"register",
      "username":"User name",
      "VerificationCode":"Verification code",
      "password":"password",
      "InvitationCode":"Invitation code",

    },
    download: {
      "line1": "Fast trading, secure storage, new generation of cryptocurrency trading products",
      "line2": "It is convenient for you to open transactions anytime and anywhere",
      "line3": "Download a safe and efficient currency transaction application",
    },
    contract: {
      "orderList": "Order Form",
      "minute": "minute",
      "hour": "hour",
      "Time-sharing": "Time-sharing",
      "contract": "Futures",
      "all": "all",
      "open": "Open",
      "close": "Close",
      "limit_order": "Limit order",
      "market_order": "Market order",
      "price": "price",
      "inputPrice": "Please enter the price",
      "amount": "quantity",
      "inputAmount": "Please enter the quantity",
      "money": "Amount",
      "inputMoney": "Please enter the amount",
      "available": "Available",
      "avail": "Avail",
      "buy_up": "purchase",
      "sell_out": "sell out",
      "cost": "bond",
      "max_short": "Short",
      "max_long": "Long",
      "open_orders": "Open orders",
      "positions": "Positions",
      "history": "History",
      "symbol": "Symbol",
      "lever": "lever",
      "direction": "direction",
      "hold_shares_volume": "open interest",
      "open_average_price": "AVG cost",
      "operation": "Operation",
      "noData": "No data",
      "margin_ratio": "Margin ratio",
      "market_price": "Market price",
      "estliqprice": "Est.liq.price",
      "openPrice": "AVG cost",
      "closePrice": "ill price",
      "profit": "PnL",
      "Yield": "Yield",
      "fee": "Fee",
      "total_amount": "Total amount",
      "time": "Time",
      "status": "state",
      "close_fast": "Close all",
      "close_limit": "Limit",
      "close_market": "Market",
      "availAmount": "Avail",
      "warning1": "Please enter the price first",
      "cancel": "Cancel",
      "stop": "Stop",
      "startPrice": "Trigger Price",
      "takeProfit": "take profit",
      "stopLoss": "stop loss",
      "message1": "Please choose Take Profit or Stop Loss",
      "message2": "Please select the stop loss trigger price",
      "message3": "Please select the quantity",
      "message4": "The take profit price must not be lower than the opening price",
      "message5": "The stop loss price cannot be higher than the opening price",
    },
    options: {
      "current_trading": "Current trading",
      "order_history": "Transaction history",
      "open_time": "Open",
      "close_time": "Close",
      "count_down": "Count down",
      "Buy_up": "Buy up",
      "Buy_down": "Buy down",
      "options": "Options",
      "estimatedProfit": "Estimated profit",
      "history": "Transaction history",
      "rise": "rise",
      "fall": "fall",
      "upAndDown": "Up and down",
      "dan": "",
      "warning1": "Your balance is insufficient, please re-enter the transaction quantity",
      "warning2": "Please enter the transaction quantity correctly",
    },
    translateNav: {
      "line1": "Search currency",
      "line2": "ALL",
    },
    userInfo: {
      "title": "Personal overview",
      "nickname": "nickname",
      "assets": "Asset distribution",
      "allAssets": "total assets",
      "Todayearnings": "Today's earnings",
      "account": "account",
      "CapitalAccount": "Capital account",
      "optionsAccount": "Option account",
      "contractAccount": "Contract account",
      "AssetEstimation": "Asset estimation",
      "Recharge": "Recharge",
      "CashWithdrawal": "Cash withdrawal",
      "CapitalFlow": "Capital flow",
      "date": "date",
      "quantity": "quantity",
      "operation": "operation",
      "Transfer": "Transfer",

    },
    Recharge: {
      "buy": "buy",
      "WalletAddress": "Wallet Address",
      "tip": "recharge type",
    },
    withdrawal: {
      "withdrawal": "Withdrawal",
      "WalletAddress": "Wallet address",
      "inputWalletAddress": "enter the wallet address",
      "AvailableBalance": "Available balance",
      "all": "Full withdrawal",
      "wantWithdrawal": "Cash withdrawal",
      "selectNetWork": "Select Network",
      "withdrawalUSDT": "Withdrawal of USDT",
    },
    Transfer: {
      "title": "Capital transfer",
      "from": "from",
      "to": "to",
      "capitalAccount": "Capital account",
      "optionsAccount": "Option account",
      "contractAccount": "Contract account",
      "amount": "quantity",
      "all": "All",
      "cancel": "Cancel",
      "confirm": "Confirm",
    },
    assets: {
      "title": "Asset distribution",
      "allAssets": "total assets",
      "account": "Account",
      "Recharge": "Recharge",
      "withdrawal": "withdrawal",
      "Transfer": "Transfer",
    },
    assetEstimation: {
      "title": "Asset estimation",

    },
    capitalFlow:{
      "title":"Capital flow",
      "date":"date",
      "amount":"quantity",
      "operation": "operation",
      "Withdrawing": "Withdrawing",
    }
  },
}
