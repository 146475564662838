export default {
    message: {
        index: {
            heardTab: {
                "buyCoins": "Acheter des coins",
                "transaction": "Transaction",
                "download": "Télécharger",
                "personalCenter": "Centre",
                "options": "Options",
                "contract": "Contrat",
                "optionDetail": "Trading facile de crypto-monnaies",
                "contractDetail": "Trading de contrats de crypto-monnaies",
                "signIn": "connecter",
                "register": "S'inscrire",
                "userDetail": "Détails de l'utilisateur",
                "outLogin": "Se déconnecter"

            },
            bottomTab: {
                "clause": "Clause et politique",
                "rate": "Tarif",
                "risk": "Avertissement de risque",
                "moneyLaundering": "Blanchiment d'argent",
                "server": "Conditions de service",
                "Privacy": "Déclaration de confidentialité",
                "support": "Prise en charge de nombreuses devises"
            },
            middleContent: {
                "line1": "Achetez, échangez et détenez une variété de cryptomonnaies sur paxfulpro",
                "line2": "Commencez votre première transaction en quelques étapes faciles.",
                "line3": "Restez à jour avec notre application en consultant les dernières informations à tout moment.",
                "line4": "Choisissez le trading au comptant de Bitcoin (BTC) et profitez de frais de transaction nuls.",
                "line5": "Plus d'un million d'utilisateurs dans le monde",
                "line6": "Dernières tendances du marché",
                "line7": "Vous pouvez choisir entre les marchés et les contrats pour investir.",
                "line8": "Investissements raisonnables",
                "line9": "Obtenez des statistiques complètes sur les actions des vendeurs et des acheteurs pour prendre des décisions éclairées.",
                "registerLearn": "Inscrivez-vous",
                "line11": "Statistiques détaillées en temps réel",
                "line12": "Consultez toutes les informations pertinentes en temps réel de n'importe où et décidez comment opérer et investir.",
                "line13": "Découvrez les dernières tendances du marché et gagnez des bénéfices immédiats.",
                "join": "Rejoignez"
            },
        },
        signIn: {
            "signIn": "connecter",
            "userName": "Nom d'utilisateur",
            "password": "Mot de passe",
            "forget": "Mot de passe oublié?",
            "register": "S'inscrire"
        },
        forget: {
            "forget": "Mot de passe oublié",
            "email": "E-mail",
            "code": "Code de vérification",
            "inputPassword": "Nouveau mot de passe",
            "confirmPassword": "Confirmer le nouveau mot de passe",
            "confirm": "Confirmer",
            "send": "Envoyer",
            "warning1": "Veuillez entrer votre adresse e-mail",
            "warning2": "Veuillez entrer le code de vérification",
            "warning3": "Veuillez entrer un mot de passe",
            "warning4": "Veuillez confirmer le mot de passe",
            "warning5": "Les deux mots de passe saisis ne correspondent pas. Veuillez les saisir à nouveau."
        },
        register: {
            "title": "Inscription",
            "username": "Nom d'utilisateur",
            "VerificationCode": "Code de vérification",
            "password": "Mot de passe",
            "InvitationCode": "Code d'invitation"

        },
        download: {
            "line1": "Trading ultra-rapide, stockage sécurisé, produits de trading de crypto-monnaies de nouvelle génération",
            "line2": "Permet de réaliser des transactions en toute simplicité, où que vous soyez",
            "line3": "Téléchargez l'application de trading de devises sécurisée et efficace"
        },
        contract: {
            "orderList": "Liste des ordres",
            "minute": "Minute",
            "hour": "Heure",
            "Time-sharing": "Horaire",
            "contract": "Contrat",
            "all": "Tout",
            "open": "Ouvrir",
            "close": "Fermer",
            "limit_order": "Ordre limite",
            "market_order": "Ordre au marché",
            "price": "Prix",
            "inputPrice": "Entrez le prix",
            "amount": "Montant",
            "inputAmount": "Entrez la quantité",
            "money": "Montant",
            "inputMoney": "Entrez le montant",
            "available": "Disponible",
            "avail": "Disponible pour fermer",
            "buy_up": "Achat",
            "sell_out": "Vente",
            "cost": "Marge",
            "max_short": "Court maximum",
            "max_long": "Long maximum",
            "open_orders": "Ordres ouverts",
            "positions": "Positions",
            "history": "Historique des ordres",
            "symbol": "Symbole",
            "lever": "Effet de levier",
            "direction": "Direction",
            "hold_shares_volume": "Volume détenu",
            "open_average_price": "Prix d'ouverture moyen",
            "operation": "Opération",
            "noData": "Pas de données",
            "margin_ratio": "Ratio de marge",
            "market_price": "Prix du marché",
            "estliqprice": "Prix de liquidation estimé",
            "openPrice": "Prix d'ouverture moyen",
            "closePrice": "Prix de clôture moyen",
            "profit": "Profit",
            "Yield": "Rendement",
            "fee": "Frais",
            "total_amount": "Montant total",
            "time": "Heure",
            "status": "Statut",
            "close_fast": "Clôture complète au prix du marché",
            "close_limit": "Ordre limite",
            "close_market": "Ordre au marché",
            "availAmount": "Quantité disponible pour la fermeture",
            "warning1": "Veuillez entrer d'abord le prix",
            "cancel": "Annuler",
            "stop": "Stop-loss / Take-profit",
            "stopAmount": "Quantité disponible pour l'opération",
            "startPrice": "Prix de déclenchement",
            "takeProfit": "Take-profit",
            "stopLoss": "Stop-loss",
            "message1": "Veuillez choisir entre le stop-loss ou le take-profit",
            "message2": "Veuillez choisir le prix de déclenchement pour le stop-loss ou le take-profit",
            "message3": "Veuillez choisir la quantité",
            "message4": "Le prix de take-profit ne peut pas être inférieur au prix d'ouverture",
            "message5": "Le prix de stop-loss ne peut pas être supérieur au prix d'ouverture",
            "message6": "Le prix de prise de profit ne doit pas être supérieur au prix d'ouverture.",
            "message7": "Le prix de stop-loss ne doit pas être inférieur au prix d'ouverture."
        },
        options: {
            "current_trading": "Position en cours",
            "order_history": "Historique des ordres",
            "open_time": "Heure d'ouverture",
            "close_time": "Heure de clôture",
            "count_down": "Compte à rebours",
            "Buy_up": "Acheter à la hausse",
            "Buy_down": "Acheter à la baisse",
            "options": "Trading binaire",
            "estimatedProfit": "Profit estimé",
            "history": "Évolution récente",
            "rise": "Hausse",
            "fall": "Baisse",
            "upAndDown": "Hausse et baisse",
            "dan": "Simple",
            "warning1": "Votre solde est insuffisant. Veuillez saisir à nouveau la quantité de trading.",
            "warning2": "Veuillez saisir une quantité de trading correcte."
        },
        translateNav: {
            "line1": "Rechercher une devise",
            "line2": "Toutes"
        },
        userInfo: {
            "title": "Aperçu personnel",
            "nickname": "Pseudonyme",
            "assets": "Répartition des actifs",
            "allAssets": "Total des actifs",
            "Todayearnings": "Gains du jour",
            "account": "Compte",
            "CapitalAccount": "Compte de capital",
            "optionsAccount": "Compte d'options",
            "contractAccount": "Compte de contrat",
            "AssetEstimation": "Estimation des actifs",
            "Recharge": "Recharger",
            "CashWithdrawal": "Retrait d'espèces",
            "CapitalFlow": "Flux de capital",
            "date": "Date",
            "quantity": "Quantité",
            "operation": "Opération",
            "Transfer": "Transfert"

        },
        Recharge: {
            "buy": "Acheter",
            "WalletAddress": "Adresse du portefeuille",
            "tip": "Type de recharge"

        },
        withdrawal: {
            "withdrawal": "Retrait",
            "WalletAddress": "Adresse du portefeuille",
            "inputWalletAddress": "Veuillez entrer l'adresse du portefeuille",
            "AvailableBalance": "Solde disponible",
            "all": "Retirer tout",
            "wantWithdrawal": "Je veux retirer",
            "selectNetWork": "Sélectionner le réseau",
            "withdrawalUSDT": "Retirer USDT"
        },
        Transfer: {
            "title": "Transfert de fonds",
            "from": "De",
            "to": "À",
            "capitalAccount": "Compte de capital",
            "optionsAccount": "Compte d'options",
            "contractAccount": "Compte de contrat",
            "amount": "Montant",
            "all": "Tout transférer",
            "cancel": "Annuler",
            "confirm": "Confirmer"
        },
        assets: {
            "title": "Répartition des actifs",
            "allAssets": "Total des actifs",
            "account": "Compte",
            "Recharge": "Recharger",
            "withdrawal": "Retrait",
            "Transfer": "Transfert"
        },
        assetEstimation: {
            "title": "Estimation des actifs"
        },
        capitalFlow: {
            "title": "Flux de fonds",
            "date": "Date",
            "amount": "Montant",
            "operation": "Opération",
            "Withdrawing": "Retrait en cours"
        },
        verifyUser: {
            "Authenti": "Authentification",
            "username": "Nom",
            "idCard": "Numéro de carte d'identité",
            "fond": "Recto",
            "reverse": "Verso",
            "submit": "Soumettre",
            "inputId": "Veuillez d'abord remplir le numéro de carte d'identité",
            "inputUsername": "Veuillez remplir le nom",
            "type": "Le téléchargement de l'image de profil doit être au format JPG ou PNG !",
            "size": "La taille de l'image de profil téléchargée ne doit pas dépasser 2 Mo !",
            "inputfond": "Veuillez télécharger la photo du recto de la carte d'identité",
            "inputreverse": "Veuillez télécharger la photo du verso de la carte d'identité"
        }
    },
}
