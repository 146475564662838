<template>
  <div class='buttonScreen '
       :style="{'--widths':width,
  '--height':height,
  '--color':color,
  '--fontsize':fontsize,
  '--backgroundColor':backgroundColor}"

       @click.stop='clickDocument'
       :class='loading?"disable":""'
  >
    <span>{{ word }}</span>
    <div class='loading' v-show='loading'>
      <img src='../../assets/image/button/loading.png' alt=''>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    width: {
      type: String,
      default: "200px",
    },
    height: {
      type: String,
      default: "60px",
    },
    backgroundColor: {
      type: String,
      default: "#000000",
    },
    color: {
      type: String,
      default: "#ffffff",
    },
    fontsize: {
      type: String,
      default: "24px",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    word: {
      type: String,
      default: "按钮",
    },
  },
  data () {
    return {}
  },
  methods: {
    clickDocument () {
      if (this.loading) return
      console.log("触发了点击事件")
    },
  },
}
</script>
<style scoped lang='scss'>
.disable {
  //pointer-events:none;
  cursor: not-allowed; // 一个红色的圈加一个斜杠，表示禁止的意思

}

@keyframes rotate {
  0% {
    transform: rotateZ(0);
  }
  100% {
    transform: rotateZ(180deg);
  }
}

.buttonScreen {
  width: var(--widths);
  height: var(--height);
  text-align: center;
  background-color: var(--backgroundColor);
  border-radius: 4px;
  line-height: var(--height);

  span {
    color: var(--color);
    font-size: var(--fontsize);
  }

  .loading {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
    overflow: hidden;

    img {
      height: 100%;
      animation: rotate 4s infinite linear 0s;
    }
  }

}
</style>
