export default {
  message: {
    index: {
      heardTab: {
        "buyCoins": "买币",
        "transaction": "交易",
        "download": "下载",
        "personalCenter": "个人中心",
        "options": "期权",
        "contract": "合约",
        "optionDetail": "轻松交易数字货币",
        "contractDetail": "交易数字货币合约",
        "signIn": "登录",
        "register": "注册",
        "userDetail": "用户详情",
        "outLogin": "退出登录",

      },
      bottomTab: {
        "clause": "条款与政策",
        "rate": "费率标准",
        "risk": "风险提示",
        "moneyLaundering": "反洗钱",
        "server": "服务条款",
        "Privacy": "隐私声明",
        "support": "支持海量货币",
      },
      middleContent: {
        "line1": "在paxfulpro上购买、交易和持有海量加密货币",
        "line2": "轻松几步，开启首笔交易。",
        "line3": "通过我们的App，随时了解最新资讯。",
        "line4": "选择特定比特币(BTC)现货交易，畅享零交易手续费",
        "line5": "全球1000000+用户",
        "line6": "最近市场行情",
        "line7": "您可以选择市场和合约投资",
        "line8": "合理的投资",
        "line9": "获取卖家和买家的行为完整统计数据，帮您做出合理的决定",
        "registerLearn": "注册了解",
        "line11": "详细实时的统计",
        "line12": "您可以在任何地方实时查看所有相关信息并决定如何操作与投资",
        "line13": "了解市场最新行情，立即赚取收益",
        "join": "立即加入",
      },
    },
    signIn: {
      "signIn": "登录",
      "userName": "用户名",
      "password": "密码",
      "forget": "忘记密码?",
      "register": "注册",
    },
    forget: {
      "forget": "忘记密码",
      "email": "邮箱",
      "code": "验证码",
      "inputPassword": "输入新密码",
      "confirmPassword": "确认新密码",
      "confirm": "确认",
      "send": "发送",
      "warning1": "请输入邮箱账号",
      "warning2": "请输入验证码",
      "warning3": "请输入密码",
      "warning4": "请输入确认密码",
      "warning5": "两次密码输入不一致，请重新输入。",
    },
    register: {
      "title": "注册",
      "username": "用户名",
      "VerificationCode": "验证码",
      "password": "密码",
      "InvitationCode": "邀请码",

    },
    download: {
      "line1": "极速交易、安全存储、新一代加密货币交易产品",
      "line2": "方便您随时随地开立交易",
      "line3": "下载安全高效的货币交易应用程序",
    },
    contract: {
      "orderList": "订单表",
      "minute": "分",
      "hour": "时",
      "Time-sharing": "分时",
      "contract": "合约交易",
      "all": "全仓",
      "open": "开仓",
      "close": "平仓",
      "limit_order": "限价委托",
      "market_order": "市价委托",
      "price": "价格",
      "inputPrice": "请输入价格",
      "amount": "数量",
      "inputAmount": "请输入数量",
      "money": "金额",
      "inputMoney": "请输入金额",
      "available": "可用",
      "avail": "可平",
      "buy_up": "买入",
      "sell_out": "卖出",
      "cost": "保证金",
      "max_short": "可开空",
      "max_long": "可开多",
      "open_orders": "当前委托",
      "positions": "当前仓位",
      "history": "历史委托",
      "symbol": "币种",
      "lever": "杠杆",
      "direction": "方向",
      "hold_shares_volume": "持仓量",
      "open_average_price": "开仓均价",
      "operation": "操作",
      "noData": "暂无数据",
      "margin_ratio": "保证金率",
      "market_price": "标记价格",
      "estliqprice": "预估强平",
      "openPrice": "开仓均价",
      "closePrice": "平仓均价",
      "profit": "收益",
      "Yield": "收益率",
      "fee": "手续费",
      "total_amount": "委托总量",
      "time": "时间",
      "status": "状态",
      "close_fast": "市价全平",
      "close_limit": "限价",
      "close_market": "市价",
      "availAmount": "可平量",
      "warning1": "请先输入价格",
      "cancel": "取消",
      "stop": "止盈止损",
      "stopAmount": "可操作数量",
      "startPrice": "触发价格",
      "takeProfit": "止盈",
      "stopLoss": "止损",
      "message1": "请选择止盈还是止损",
      "message2": "请选择止盈止损触发价格",
      "message3": "请选择数量",
      "message4": "止盈价格不得低于开仓价格",
      "message5": "止损价格不得高于开仓价格",

    },
    options: {
      "current_trading": "当前持仓",
      "order_history": "历史记录",
      "open_time": "开仓时间",
      "close_time": "平仓时间",
      "count_down": "倒计时",
      "Buy_up": "买涨",
      "Buy_down": "买跌",
      "options": "二元交易",
      "estimatedProfit": "预估盈利",
      "history": "近期涨跌",
      "rise": "涨",
      "fall": "跌",
      "upAndDown": "涨跌",
      "dan": "单",
      "warning1": "您的余额不足,请重新输入交易数量",
      "warning2": "请正确输入交易数量",
    },
    translateNav: {
      "line1": "搜索币种",
      "line2": "全部",
    },
    userInfo: {
      "title": "个人总览",
      "nickname": "昵称",
      "assets": "资产分布",
      "allAssets": "总资产",
      "Todayearnings": "今日收益",
      "account": "账户",
      "CapitalAccount": "资金账户",
      "optionsAccount": "期权账户",
      "contractAccount": "合约账户",
      "AssetEstimation": "资产预估",
      "Recharge": "充值",
      "CashWithdrawal": "提现",
      "CapitalFlow": "资金流转",
      "date": "日期",
      "quantity": "数量",
      "operation": "操作",
      "Transfer": "划转",

    },
    Recharge: {
      "buy": "购买",
      "WalletAddress": "钱包地址",
      "tip": "充值类型",

    },
    withdrawal: {
      "withdrawal": "提现",
      "WalletAddress": "钱包地址",
      "inputWalletAddress": "请输入钱包地址",
      "AvailableBalance": "可用余额",
      "all": "全部提现",
      "wantWithdrawal": "我要提现",
      "selectNetWork": "选择网络",
      "withdrawalUSDT": "提现USDT",
    },
    Transfer: {
      "title": "资金划转",
      "from": "从",
      "to": "到",
      "capitalAccount": "资金账号",
      "optionsAccount": "期权账号",
      "contractAccount": "合约账号",
      "amount": "数量",
      "all": "全部划转",
      "cancel": "取消",
      "confirm": "确认",
    },
    assets: {
      "title": "资产分布",
      "allAssets": "总资产",
      "account": "账户",
      "Recharge": "充值",
      "withdrawal": "提现",
      "Transfer": "划转",
    },
    assetEstimation: {
      "title": "资产预估",
    },
    capitalFlow: {
      "title": "资金流转",
      "date": "日期",
      "amount": "数量",
      "operation": "操作",
      "Withdrawing": "提现中",
    },
  },
}
