export default {
    message: {
        index: {
            heardTab: {
                "buyCoins": "Comprar monedas",
                "transaction": "Transacción",
                "download": "Descargar",
                "personalCenter": "Centro",
                "options": "Opciones",
                "contract": "Contrato",
                "optionDetail": "Comercio fácil de criptomonedas",
                "contractDetail": "Comercio de contratos de criptomonedas",
                "signIn": "Iniciar",
                "register": "Registrarse",
                "userDetail": "Detalles del usuario",
                "outLogin": "Cerrar sesión"

            },
            bottomTab: {
                "clause": "Cláusula y política",
                "rate": "Tarifa",
                "risk": "Aviso de riesgo",
                "moneyLaundering": "Lavado de dinero",
                "server": "Términos de servicio",
                "Privacy": "Declaración de privacidad",
                "support": "Soporte para múltiples monedas"
            },
            middleContent: {
                "line1": "Compre, opere y mantenga una amplia variedad de criptomonedas en paxfulpro",
                "line2": "Comience su primera transacción con unos pocos pasos sencillos.",
                "line3": "Manténgase actualizado con nuestra aplicación y obtenga las últimas noticias en todo momento.",
                "line4": "Seleccione operaciones al contado específicas de Bitcoin (BTC) y disfrute de comisiones de transacción cero.",
                "line5": "Más de 1 millón de usuarios en todo el mundo",
                "line6": "Tendencias de mercado recientes",
                "line7": "Puede elegir entre mercados y contratos para invertir.",
                "line8": "Inversiones razonables",
                "line9": "Obtenga estadísticas completas sobre las acciones de vendedores y compradores para tomar decisiones informadas.",
                "registerLearn": "Regístrese",
                "line11": "Estadísticas detalladas en tiempo real",
                "line12": "Puede ver toda la información relevante en tiempo real desde cualquier lugar y decidir cómo operar e invertir.",
                "line13": "Conozca las últimas tendencias del mercado y obtenga ganancias de inmediato.",
                "join": "Únase ahora"
            },
        },
        signIn: {
            "signIn": "Iniciar sesión",
            "userName": "Nombre de usuario",
            "password": "Contraseña",
            "forget": "¿Olvidaste tu contraseña?",
            "register": "Registrarse"
        },
        forget: {
            "forget": "Olvidé mi contraseña",
            "email": "Correo electrónico",
            "code": "Código de verificación",
            "inputPassword": "Ingrese nueva contraseña",
            "confirmPassword": "Confirmar nueva contraseña",
            "confirm": "Confirmar",
            "send": "Enviar",
            "warning1": "Por favor, ingrese su dirección de correo electrónico",
            "warning2": "Por favor, ingrese el código de verificación",
            "warning3": "Por favor, ingrese una contraseña",
            "warning4": "Por favor, confirme la contraseña",
            "warning5": "Las contraseñas ingresadas no coinciden. Por favor, ingréselas nuevamente."
        },
        register: {
            "title": "Registro",
            "username": "Nombre de usuario",
            "VerificationCode": "Código de verificación",
            "password": "Contraseña",
            "InvitationCode": "Código de invitación"

        },
        download: {
            "line1": "Trading rápido, almacenamiento seguro, productos de trading de criptomonedas de nueva generación",
            "line2": "Le permite realizar transacciones de manera conveniente en cualquier momento y lugar",
            "line3": "Descargue la aplicación de trading de moneda segura y eficiente"
        },
        contract: {
            "orderList": "Lista de órdenes",
            "minute": "min",
            "hour": "h",
            "Time-sharing": "Compartir tiempo",
            "contract": "Contrato",
            "all": "Todo",
            "open": "Abrir",
            "close": "Cerrar",
            "limit_order": "Orden limitada",
            "market_order": "Orden de mercado",
            "price": "Precio",
            "inputPrice": "Ingrese el precio",
            "amount": "Cantidad",
            "inputAmount": "Ingrese la cantidad",
            "money": "Monto",
            "inputMoney": "Ingrese el monto",
            "available": "Disponible",
            "avail": "Disponible",
            "buy_up": "Comprar",
            "sell_out": "Vender",
            "cost": "Costo",
            "max_short": "Short máximo",
            "max_long": "Long máximo",
            "open_orders": "Órdenes abiertas",
            "positions": "Posiciones",
            "history": "Historial",
            "symbol": "Símbolo",
            "lever": "Apalancamiento",
            "direction": "Dirección",
            "hold_shares_volume": "Volumen de posiciones",
            "open_average_price": "Precio de apertura promedio",
            "operation": "Operación",
            "noData": "Sin datos",
            "margin_ratio": "Ratio de margen",
            "market_price": "Precio de mercado",
            "estliqprice": "Precio estimado de liquidación",
            "openPrice": "Precio de apertura",
            "closePrice": "Precio de cierre",
            "profit": "Ganancia",
            "Yield": "Rendimiento",
            "fee": "Comisión",
            "total_amount": "Cantidad total",
            "time": "Hora",
            "status": "Estado",
            "close_fast": "Cerrar mercado",
            "close_limit": "Límite",
            "close_market": "Mercado",
            "availAmount": "Cantidad disponible",
            "warning1": "Por favor, ingrese primero un precio",
            "cancel": "Cancelar",
            "stop": "Stop Loss / Take Profit",
            "stopAmount": "Cantidad disponible",
            "startPrice": "Precio de activación",
            "takeProfit": "Take Profit",
            "stopLoss": "Stop Loss",
            "message1": "Seleccione entre Stop Loss y Take Profit",
            "message2": "Seleccione el precio de activación para Stop Loss o Take Profit",
            "message3": "Seleccione una cantidad",
            "message4": "El precio de Take Profit no puede ser menor que el precio de apertura",
            "message5": "El precio de Stop Loss no puede ser mayor que el precio de apertura",
            "message6": "El precio objetivo de beneficios no debe ser mayor que el precio de apertura.",
            "message7": "El precio de stop-loss no debe ser menor que el precio de apertura."

        },
        options: {
            "current_trading": "Posición actual",
            "order_history": "Historial de órdenes",
            "open_time": "Hora de apertura",
            "close_time": "Hora de cierre",
            "count_down": "Cuenta regresiva",
            "Buy_up": "Comprar alza",
            "Buy_down": "Comprar baja",
            "options": "Operaciones binarias",
            "estimatedProfit": "Beneficio estimado",
            "history": "Cambios recientes",
            "rise": "Subir",
            "fall": "Bajar",
            "upAndDown": "Subir y bajar",
            "dan": "Individual",
            "warning1": "Su saldo es insuficiente. Por favor, ingrese nuevamente la cantidad de trading.",
            "warning2": "Por favor, ingrese una cantidad de trading correcta."
        },
        translateNav: {
            "line1": "Buscar moneda",
            "line2": "Todas"
        },
        userInfo: {
            "title": "Resumen personal",
            "nickname": "Apodo",
            "assets": "Distribución de activos",
            "allAssets": "Activos totales",
            "Todayearnings": "Ganancias de hoy",
            "account": "Cuenta",
            "CapitalAccount": "Cuenta de capital",
            "optionsAccount": "Cuenta de opciones",
            "contractAccount": "Cuenta de contrato",
            "AssetEstimation": "Estimación de activos",
            "Recharge": "Recargar",
            "CashWithdrawal": "Retiro de efectivo",
            "CapitalFlow": "Flujo de capital",
            "date": "Fecha",
            "quantity": "Cantidad",
            "operation": "Operación",
            "Transfer": "Transferir"

        },
        Recharge: {
            "buy": "Comprar",
            "WalletAddress": "Dirección de la billetera",
            "tip": "Tipo de recarga"
        },
        withdrawal: {
            "withdrawal": "Retiro",
            "WalletAddress": "Dirección de la billetera",
            "inputWalletAddress": "Ingrese la dirección de la billetera",
            "AvailableBalance": "Saldo disponible",
            "all": "Retirar todo",
            "wantWithdrawal": "Quiero retirar",
            "selectNetWork": "Seleccionar red",
            "withdrawalUSDT": "Retirar USDT"
        },
        Transfer: {
            "title": "Transferencia de fondos",
            "from": "Desde",
            "to": "A",
            "capitalAccount": "Cuenta de capital",
            "optionsAccount": "Cuenta de opciones",
            "contractAccount": "Cuenta de contrato",
            "amount": "Cantidad",
            "all": "Transferir todo",
            "cancel": "Cancelar",
            "confirm": "Confirmar"
        },
        assets: {
            "title": "Distribución de activos",
            "allAssets": "Total de activos",
            "account": "Cuenta",
            "Recharge": "Recargar",
            "withdrawal": "Retiro",
            "Transfer": "Transferencia"
        },
        assetEstimation: {
            "title": "Estimación de activos"
        },
        capitalFlow: {
            "title": "Flujo de fondos",
            "date": "Fecha",
            "amount": "Cantidad",
            "operation": "Operación",
            "Withdrawing": "Retirando"
        },
        verifyUser: {
            "Authenti": "Autenticación",
            "username": "Nombre",
            "idCard": "Número de documento de identidad",
            "fond": "Anverso",
            "reverse": "Reverso",
            "submit": "Enviar",
            "inputId": "Por favor, primero ingrese el número de documento de identidad",
            "inputUsername": "Por favor, ingrese su nombre",
            "type": "¡La imagen de perfil cargada debe estar en formato JPG o PNG!",
            "size": "¡El tamaño de la imagen de perfil cargada no puede superar los 2 MB!",
            "inputfond": "Por favor, cargue una foto del anverso del documento de identidad",
            "inputreverse": "Por favor, cargue una foto del reverso del documento de identidad"
        }
    },
}
