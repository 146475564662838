import axios from "axios"

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  timeout: 5000, // 请求超时时间
})

// request拦截器
service.interceptors.request.use(
  (config) => {
    // do something 在发送请求前
    const token = localStorage.access_token
    const language = localStorage.getItem("ucoinexLang") || "en"
    if (token) {
      // 让每个请求携带token
      // ['X-Token']为自定义key
      // 请根据实际情况自行修改
      config.headers["authorization"] = "Bearer " + token
    }
    config.headers["lang"] = language
    return config
  },
  (error) => {
    // do something 当请求错误
    console.log(error) // for debug
    return Promise.reject(error)
  },
)

// respone拦截器
service.interceptors.response.use(
  /**
   * 如果你想获取 http 信息，例如 headers 或 status
   * 请 return  response => response
   */

  /**
   * 下面的注释为通过response自定义code来标示请求状态，当code返回如下情况为权限有问题，登出并返回到登录页
   * 如通过xmlhttprequest 状态码标识 逻辑可写在下面error中
   */
  (response) => {
    const res = response
    console.log("返回的结果", res)
    // 如果返回的自定义code不是20000, 认定为error。
    return res
  },
  (error) => {
    console.log("err" + error.response.status) // for debug
    if (error.response.status == "401") {
      console.log("没登录")
      localStorage.clear("access_token")
      window.location.href = "/login"
    }
    return Promise.reject(error)

  },
)

export default service
