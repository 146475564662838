export default {
  message: {
    index: {
      heardTab: {
        "buyCoins": "Para alın.",
        "transaction": "transaction",
        "download": "indir",
        "personalCenter": "user",
        "options": "seçenek",
        "contract": "sözleşme",
        "optionDetail": "Dijital para kolayca ticaret eder.",
        "contractDetail": "Dijital para sözleşmesi",
        "signIn": "İmzala",
        "register": "kayıt",
        "userDetail": "Kullanıcı detayları",
        "outLogin": "oturumu Kapat",

      },
      bottomTab: {
        "clause": "Termler ve politikalar",
        "rate": "Rate standart",
        "risk": "Risk ifadesi",
        "moneyLaundering": "Anti-money laundering",
        "server": "Servis şartları",
        "Privacy": "Özel Görüntü",
        "support": "Yüksek para destekle",
      },
      middleContent: {
        "line1": "paxfulpro üzerinde büyük kriptovalet alın, ticaret ve tutun",
        "line2": "İlk transaksyonu a çmak için birkaç kolay adım yap.。",
        "line3": "Uygulamalarımızla birlikte kalın.。",
        "line4": "Özel bir Bitcoin (BTC) noktası aktarımı seçin ve sıfır aktarım ücretini tadın edin",
        "line5": "1000000+kullanıcı dünyaya",
        "line6": "Son zamanlarda pazar şartları",
        "line7": "Pazar ve sözleşme yatırımlarını seçebilirsiniz",
        "line8": "Sebebili yatırım",
        "line9": "Satıcıların ve alıcıların davranışları hakkında tamamen istatistiksel verileri alın, sizi mantıklı karar vermeye yardım etmek için",
        "registerLearn": "Öğrenmek için kayıt",
        "line11": "Ayrıntılı gerçek zamanlı istatistikler",
        "line12": "Gerçek zamanda her yerde bütün önemli bilgileri görüp nasıl çalışacağına ve yatırımlarına karar verebilirsiniz",
        "line13": "Son pazar durumu öğrenin ve hemen kazanın.",
        "join": "Şimdi katılın",
      },
    },
    signIn: {
      "signIn": "İmzala",
      "userName": "kullanıcı adı",
      "password": "parola",
      "forget": "Parola unutun?",
      "register": "kayıt",
    },
    forget: {
      "forget": "Parola unutun",
      "email": "posta kutusu",
      "code": "Kontrol Kodu",
      "inputPassword": "Yeni bir parola girin",
      "confirmPassword": "Yeni parolanı doğrula",
      "confirm": "onaylayın",
      "send": "gönderin",
      "warning1": "Lütfen e-posta hesabınızı girin",
      "warning2": "Lütfen doğrulama kodunu girin",
      "warning3": "Lütfen bir parola girin",
      "warning4": "Lütfen doğrulama parolanı girin",
      "warning5": "İki parola uygun değil, lütfen yeniden girin.",
    },
    register: {
      "title": "kayıt",
      "username": "kullanıcı adı",
      "VerificationCode": "Kontrol Kodu",
      "password": "parola",
      "InvitationCode": "Davet kodu",

    },
    download: {
      "line1": "Hızlı ticaret, güvenli depo, yeni nesil kriptomonet ticaret ürünlerinin",
      "line2": "Her zaman ve her yerde transaksyonları açmanız için uygun.",
      "line3": "Güvenli ve etkili para aktarım uygulamasını indir",
    },
    contract: {
      "orderList": "Sıra Şekilleri",
      "minute": "branch",
      "hour": "Saat",
      "Time-sharing": "Zaman paylaşımı",
      "contract": "Kontrakt aktarımı",
      "all": "Bütün depo",
      "open": "open",
      "close": "close",
      "limit_order": "sınır",
      "market_order": "pazar",
      "price": "Ödül",
      "inputPrice": "Lütfen fiyatı girin",
      "amount": "miktarda",
      "inputAmount": "Lütfen miktarı girin",
      "money": "para miktarı",
      "inputMoney": "Lütfen miktarı girin",
      "available": "ulaşılabilir",
      "avail": "Keping",
      "buy_up": "satın alın",
      "sell_out": "satın",
      "cost": "satın",
      "max_short": "Açılabilir hava",
      "max_long": "Can open more",
      "open_orders": "Şimdiki deleksiyon",
      "positions": "Mevcut pozisyon",
      "history": "Tarihi güvenlik",
      "symbol": "para",
      "lever": "lever",
      "direction": "yön",
      "hold_shares_volume": "open interest",
      "open_average_price": "Orta açılış fiyatı",
      "operation": "operasyon",
      "noData": "Veri yok",
      "margin_ratio": "Margin rate",
      "market_price": "Etiket Ödülü",
      "estliqprice": "Tahmin edilen güçlü seviye",
      "openPrice": "Orta açılış fiyatı",
      "closePrice": "Orta kapatma fiyatı",
      "profit": "kazanç",
      "Yield": "Yield",
      "fee": "Hizmet Şarj",
      "total_amount": "Güvenli toplam miktar",
      "time": "Zaman",
      "status": "Durum",
      "close_fast": "Pazar fiyatı düz.",
      "close_limit": "fiyat fiyatı",
      "close_market": "pazar fiyatı",
      "availAmount": "Eşitli miktarda",
      "warning1": "Lütfen önce fiyatı girin.",
      "cancel": "iptal et",
      "stop": "Stop",
      "startPrice": "Trigger Price",
      "takeProfit": "kar almak",
      "stopLoss": "kaybı durdurmak",
      "message1": "Please choose Take Profit or Stop Loss",
      "message2": "Please select the stop loss trigger price",
      "message3": "Please select the quantity",
      "message4": "The take profit price must not be lower than the opening price",
      "message5": "The stop loss price cannot be higher than the opening price",
    },
    options: {
      "current_trading": "Mevcut pozisyon",
      "order_history": "Tarihi",
      "open_time": "Aç Zamanı",
      "close_time": "Kapatma zamanı",
      "count_down": "Sayın aşağı",
      "Buy_up": "Buy up",
      "Buy_down": "Buy down",
      "options": "İkili transaksyon",
      "estimatedProfit": "tahmin et",
      "history": "Son zamanlarda yukarı ve aşağı",
      "rise": "rise",
      "fall": "fall",
      "upAndDown": "Up and down",
      "dan": "tek",
      "warning1": "Sizin dengeniz yetersiz, lütfen transaksyon miktarını yeniden girin",
      "warning2": "Lütfen transaksyon miktarını doğru olarak girin",
    },
    translateNav: {
      "line1": "Arama para",
      "line2": "Tüm",
    },
    userInfo: {
      "title": "Kişisel görüntü",
      "nickname": "lağap adı",
      "assets": "Varlık dağıtımı",
      "allAssets": "toplam maliyetler",
      "Todayearnings": "Bugün kazançlar",
      "account": "hesap",
      "CapitalAccount": "Ülke hesabı",
      "optionsAccount": "Seçenek hesabı",
      "contractAccount": "Kontrakt hesabı",
      "AssetEstimation": "Varlık değerlendirmesi",
      "Recharge": "Tekrar yükle",
      "CashWithdrawal": "Para çekme",
      "CapitalFlow": "Bölge akışı",
      "date": "date",
      "quantity": "miktarda",
      "operation": "operasyon",
      "Transfer": "Transfer",

    },
    Recharge: {
      "buy": "buy",
      "WalletAddress": "Cüzdanın adresi",
      "tip": "Yeniden yükle türü",

    },
    withdrawal: {
      "withdrawal": "Para çekme",
      "WalletAddress": "Cüzdanın adresi",
      "inputWalletAddress": "Lütfen cüzdanın adresini girin",
      "AvailableBalance": "Available balance",
      "all": "Tam çekilme",
      "wantWithdrawal": "I want to withdraw cash",
      "selectNetWork": "Ağ Seç",
      "withdrawalUSDT": "USDT'nin kaldırılması",
    },
    Transfer: {
      "title": "Bölge aktarımı",
      "from": "from",
      "to": "to",
      "capitalAccount": "Ülke hesabı",
      "optionsAccount": "Seçenek hesabı",
      "contractAccount": "Kontrakt hesabı",
      "amount": "miktarda",
      "all": "Hepsini aktar",
      "cancel": "iptal et",
      "confirm": "onaylayın",
    },
    assets: {
      "title": "Varlık dağıtımı",
      "allAssets": "toplam maliyetler",
      "account": "hesap",
      "Recharge": "Recharge",
      "withdrawal": "withdrawal",
      "Transfer": "Transfer",
    },
    assetEstimation: {
      "title": "Varlık değerlendirmesi",
    },
    capitalFlow: {
      "title": "Bölge akışı",
      "date": "date",
      "amount": "miktarda",
      "operation": "operasyon",
      "Withdrawing": "Kaçırmak",
    },
  },
}
