<template>
  <div class='home'>
    <div class='heard'>
      <div class='left'>
        <router-link to='/'>
          <img src='../assets/image/1.png' alt=''>
        </router-link>
        <div>
          <span>
<!--        <router-link to=''>-->
            <!--          {{ $t("message.index.heardTab.buyCoins") }}-->
            <!--          </router-link>-->
        </span>
        </div>
        <div>
          <el-dropdown>
          <span class='el-dropdown-link' style='font-size: 16px'>
             {{ $t("message.index.heardTab.transaction") }}
           </span>
            <el-dropdown-menu slot='dropdown'>
              <el-dropdown-item>
                <router-link to='/binary' class='optionsList'>
                  <div class='left'>
                    <img src='@/assets/image/transaction/1.png' alt=''>
                  </div>
                  <div class='right'>
                    <span>{{ $t("message.index.heardTab.options") }}</span>
                    <span>{{ $t("message.index.heardTab.optionDetail") }}</span>
                  </div>
                </router-link>
              </el-dropdown-item>
              <el-dropdown-item>
                <router-link to='/contract' class='optionsList'>
                  <div class='left'>
                    <img src='../assets/image/transaction/2.png' alt=''>
                  </div>
                  <div class='right'>
                    <span>{{ $t("message.index.heardTab.contract") }}</span>
                    <span>{{ $t("message.index.heardTab.contractDetail") }}</span>
                  </div>
                </router-link>
              </el-dropdown-item>

            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div>
          <span>
        <router-link to='/download'>
        {{ $t("message.index.heardTab.download") }}
          </router-link>
        </span>
        </div>
      </div>
      <div class='right'>
        <!--        没有登录-->
        <div class='noAuth' v-if='!$store.state.isAuth'>
          <router-link to='/login' class='router'><span>{{ $t("message.index.heardTab.signIn") }}</span></router-link>
          <router-link to='/register' class='router'><span>{{ $t("message.index.heardTab.register") }}</span>
          </router-link>
        </div>
        <!--        已经登录了-->
        <div class='authed' v-if='$store.state.isAuth'>
          <a class='router'>
            <el-dropdown @command="handleCommand">
  <span class="el-dropdown-link">
    {{ $t("message.index.heardTab.personalCenter") }}
  </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="a">
                  {{ $t("message.index.heardTab.userDetail") }}
                </el-dropdown-item>
                <el-dropdown-item command="b">
                  {{ $t("message.index.heardTab.outLogin") }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </a>
        </div>

        <div class='language'>
          <el-dropdown @command='langeageChange'>
            <span class='el-dropdown-link'>{{ language[lang] }}</span>
            <el-dropdown-menu slot='dropdown'>
              <el-dropdown-item command='cn'>简体中文</el-dropdown-item>
              <el-dropdown-item command='en'>English</el-dropdown-item>
              <el-dropdown-item command='jp'>日本語</el-dropdown-item>
              <el-dropdown-item command='tky'>Türkçe</el-dropdown-item>
              <el-dropdown-item command='kr'>한국어</el-dropdown-item>
              <el-dropdown-item command='ge'>Deutsch</el-dropdown-item>
              <el-dropdown-item command='spin'>España</el-dropdown-item>
              <el-dropdown-item command='fc'>Français</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <router-view/>
    <div class='bottom'>
      <div class='line line1'>
        <img src='../assets/image/1.png' alt=''>
        <span>PaxfulPro</span>
      </div>
      <div class='line line2'>
        <div><span>{{ $t("message.index.bottomTab.clause") }}</span></div>
        <div><span>{{ $t("message.index.bottomTab.rate") }}</span></div>
        <div><span>{{ $t("message.index.bottomTab.risk") }}</span></div>
        <div><span>{{ $t("message.index.bottomTab.moneyLaundering") }}</span></div>
      </div>
      <div class='line line3'>
        <div><span>{{ $t("message.index.bottomTab.server") }}</span></div>
        <div><span>{{ $t("message.index.bottomTab.server") }}</span></div>
        <div><span>{{ $t("message.index.bottomTab.Privacy") }}</span></div>
      </div>
      <div class='line line4'>
        <div><span>{{ $t("message.index.bottomTab.support") }}</span></div>
        <div>
          <img src='../assets/image/bottom/1.png' alt=''>
          <img src='../assets/image/bottom/2.png' alt=''>
          <img src='../assets/image/bottom/3.png' alt=''>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {openWebsocket} from "@/utils/websocket"
import {allMarket} from "@/API/market"

export default {
  name: "HomeView",
  data() {
    return {
      language: {
        en: "English",
        cn: "简体中文",
        jp: "日本語",
        tky: "Türkçe",
        kr: "한국어",
        fc:"Français",
        spin:"España",
        ge:"Deutsch"

      },
      lang: "cn",
    }
  },
  created() {
    // 获取市场列表
    this.getAllMarketList()
    this.lang = localStorage.getItem("ucoinexLang") || "en"
  },
  methods: {
    // a是用户详情 b是退出登录
    handleCommand(symbol) {
      console.log(symbol)
      switch (symbol) {
        case "a":
          this.$router.push({path: "/userAssets"})
          break
        case "b":
          this.logout()
          break


      }
    },
    // 退出登录
    logout() {
      console.log("退出登录")
      //清掉用户的token
      localStorage.removeItem("access_token")
      // 将登录状态改为未登录
      this.$store.commit("changeIsAuth", false)
      this.$router.push({path: "/"})
    },
    //获取所有市场列表
    getAllMarketList() {
      allMarket().then(res => {
        console.log("所有市场", res)
        if (res.data.code == 200) {
          this.$store.commit("changeMarketList", res.data.data.data)
          // 打开wensocket
          openWebsocket()
        }
      })
    },
    //切换语言
    langeageChange(language) {
      this.lang = language
      localStorage.setItem("ucoinexLang", language)
      this.$i18n.locale = language// 这一句就是切换语言的
    },
  },
}
</script>
<style scoped lang='scss'>
@import "../assets/styles/common.scss";

.el-dropdown-link {
  cursor: pointer;
  color: #409EFF;
}

.el-icon-arrow-down {
  font-size: 12px;
}

.optionsList {
  display: flex;
  justify-content: left;

  .right {
    flex: 1;
    //align-items: start !important;
    justify-content: left !important;
  }
}


.selectOptions {
  display: flex;
  flex-direction: column;
  justify-content: left;
}

.el-icon-arrow-down {
  font-size: 12px;
}

.el-dropdown-menu__item {
  a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;

    &:nth-of-type(1) {
      margin-bottom: 16px;
    }

    .left {
      margin-right: 8px;
      display: flex;
      align-items: center;

      img {
        width: 24px;
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      span {
        line-height: normal;

        &:nth-of-type(1) {
          font-size: 0.175rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #363636;
        }

        &:nth-of-type(2) {
          font-size: 0.15rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ADADAD;
        }
      }
    }
  }
}

.el-dropdown-link {
  cursor: pointer;
  color: #ffffff;
}

.el-icon-arrow-down {
  font-size: 12px;
}

.home {
  .heard {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $backGround;
    padding: 0.5rem 2.625rem;

    .left {
      display: flex;
      align-items: center;

      img {
        width: 1rem;
        width: 0.75rem;
        margin-right: 0.8875rem;
      }

      div {
        margin-right: 0.6rem;
        cursor: pointer;

        span {
          a {
            font-size: 0.2rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            text-decoration: none;
          }
        }

        i {
          font-size: 0.125rem;
          color: #FFFFFF;
          margin-left: 0.075rem;
        }

        .title {
          display: flex;

          span {
            font-size: 0.15rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF
          }

          i {
            font-size: 0.125rem;
            color: #FFFFFF;
            margin-left: 0.075rem;
          }
        }
      }
    }

    .right {
      display: flex;
      align-items: center;

      .noAuth {
        display: flex;
        align-items: center;

        .router {
          width: 1.125rem;
          height: 0.4rem;
          border-radius: 4px;
          border: 1px solid #5D4FF2;
          text-align: center;
          cursor: pointer;
          text-decoration: none;

          &:nth-of-type(2) {
            background: linear-gradient(90deg, #5D4FF2 0%, #4436CF 60%, #6A5CFF 100%);
            box-shadow: 0rem 0rem 0.35rem 0.0375rem #5D4FF2;
            margin-left: 0.5rem;
          }

          span {
            font-size: 0.2rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 0.4rem;
          }
        }
      }

      .authed {
        display: flex;
        align-items: center;
        justify-content: center;

        .router {
          width: 1.125rem;
          height: 0.4rem;
          border-radius: 0.05rem;
          border: 1px solid #5D4FF2;
          text-align: center;
          cursor: pointer;
          text-decoration: none;


          background: linear-gradient(90deg, #5D4FF2 0%, #4436CF 60%, #6A5CFF 100%);
          box-shadow: 0rem 0rem 0.35rem 0.0375rem #5D4FF2;

          span {
            font-size: 0.2rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 0.4rem;
          }
        }
      }

      .language {
        width: 1.125rem;
        height: 0.4rem;
        border-radius: 4px;
        border: 1px solid #5D4FF2;
        text-align: center;
        line-height: 0.4rem;
        background: linear-gradient(90deg, #5D4FF2 0%, #4436CF 60%, #6A5CFF 100%);
        box-shadow: 0rem 0rem 0.35rem 0.0375rem #5D4FF2;
        margin-left: 0.25rem;

        .el-dropdown-link {
          cursor: pointer;
          font-size: 0.2rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 0.4rem;
        }
      }
    }
  }

  .bottom {
    background-color: $backGround;
    display: flex;
    justify-content: space-between;
    padding: 0.2625rem 1.25rem 0.5rem 1.25rem;

    .line {
      cursor: pointer;
    }

    .line1 {
      display: flex;
      align-items: center;

      img {
        width: 0.75rem;
        margin-right: 0.2rem;
      }

      span {
        font-size: 0.35rem;
        font-family: Kingsoft_Cloud_Font;
        color: #FFFFFF;
      }
    }

    .line2, .line3 {
      div {
        margin-bottom: 0.25rem;

        &:nth-of-type(1) {
          margin-bottom: 0.175rem;

          span {
            font-size: 0.225rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;

          }
        }

        span {
          font-size: 0.2rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #989898;
        }
      }
    }

    .line4 {
      div {
        span {
          font-size: 0.225rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
        }

        &:nth-of-type(2) {
          margin-top: 0.175rem;

          img {
            width: 0.3625rem;
          }
        }

      }
    }
  }
}
</style>
